<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="이벤트" />
				</div>
			</div>
		</div>
		<div class="page-content event-check eventsns">
			<div class="container">
				<div class="page-sub-header">
					<h3 class="page-sub-title"><span class="prima">동행축제 이벤트에 참여하고</span> 푸짐한 선물 받으세요.</h3>
				</div>
			</div>
			<div class="page-tab-container">
				<div class="container tab-buttons">
					<router-link to="check" class="tab-button">출석체크</router-link>
					<router-link to="dibs" class="tab-button">찜하기</router-link>
					<router-link to="sns" class="tab-button is-active">구매후기</router-link>
				</div>
			</div>
			<div class="container">
				<div class="event-check-container">
					<div class="event-check-header">
						<div class="event-check-header-banner">
							<div class="banner-titlewrap">
								<h3 class="banner-title">구매후기 이벤트</h3>
								<span class="banner-text">9월 동행제품 구매 후 </span>
								<span class="banner-text"> <span class="boldtxt">사용후기</span>를 남겨주세요. </span>
							</div>
							<div class="banner-img">
								<img src="@/assets/img/event/banner_icon02.jpg" alt="" />
							</div>
						</div>
						<div class="event-check-header-info">
							<ul class="event-check-header-info-list">
								<li v-for="(item, idx) in infoList" :key="idx">
									<span class="infolist-title">{{ item.title }}</span>
									<span class="infolist-text">{{ item.detail }}</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="event-check-guide">
						<span class="event-check-guide-title">참여방법</span>
						<div class="event-check-guide-desc no-border">
							<ul class="event-check-guide-desclist">
								<li class="event-check-guide-desclist-li">
									<div class="event-check-guide-desclist-img hidden-mobile">
										<img src="@/assets/img/event/icon_img31.png" alt="" />
									</div>
									<div class="event-check-guide-desclist-text">
										<span class="desclist-title">STEP 1</span>
										<span class="desclist-text">9월 동행제품 중 하나를 해당 상세페이지에서 구매하세요! </span>
									</div>
								</li>
								<li class="event-check-guide-desclist-li">
									<div class="event-check-guide-desclist-img hidden-mobile">
										<img src="@/assets/img/event/icon_img32.png" alt="" />
									</div>
									<div class="event-check-guide-desclist-text">
										<span class="desclist-title">STEP 2</span>
										<span class="desclist-text">구매한 제품 사진과 함께 사용 후기를 본인 SNS(인스타그램 또는 페이스북)에 필수 해시태그와 함께 게시하세요! </span>
										<div class="desclist-tag">
											<span class="tag-title">필수 해시태그</span>
											<div class="tag-item">
												<span class="tag-desc">동행축제</span>
												<span class="tag-desc">동행제품</span>
												<span class="tag-desc">구매후기</span>
											</div>
										</div>
									</div>
								</li>
								<li class="event-check-guide-desclist-li">
									<div class="event-check-guide-desclist-img hidden-mobile">
										<img src="@/assets/img/event/icon_img33.png" alt="" />
									</div>
									<div class="event-check-guide-desclist-text">
										<span class="desclist-title">STEP 3</span>
										<span class="desclist-text"> 구매내역(주문내역 화면 캡처)과 함께 게시물을 업로드한 SNS 링크를 구글폼을 통해 등록하면 참여 완료! </span>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="event-check-products">
						<span class="event-check-products-title">경품소개</span>
						<ul class="event-check-products-list">
							<li class="event-check-products-list-li" v-for="(item, idx) in prdList" :key="idx">
								<div class="event-check-products-item">
									<div class="event-check-products-item-img">
										<img :src="require(`@/assets/img/main/${item.img}`)" :alt="item.title" />
									</div>
									<div class="event-check-products-item-title">
										<span class="item-title">{{ item.title }}</span>
										<span class="item-title boldtxt">{{ item.person }}</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="event-check-button-box">
				<div class="container">
					<button type="button" class="event-check-button is-event-sns" @click="clickSnsEvent">구매후기 이벤트 참여</button>
				</div>
			</div>
			<div class="event-check-prect">
				<div class="container">
					<span class="event-check-prect-title">유의사항</span>
					<ul class="event-check-prect-list">
						<li class="event-check-prect-list-li" v-for="(item, idx) in prectList" :key="idx">
							<span class="prect-list-text">{{ item }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<event-terms-modal :visible="visibleModal" @closeModal="visibleModal = false" @agreeModal="prvcMktAgrePopup" />
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
import EventTermsModal from '@/components/content/EventTermsModal';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM } from '@/store/_mut_consts';
import { ACT_SET_PRVC_MKT_AGRC } from '@/store/_act_consts';
import { isSuccess } from '@/assets/js/utils';
import { mapGetters } from 'vuex';

export default {
	name: 'EventSns',
	components: { PageHeader, EventTermsModal },
	data: () => ({
		visibleModal: false,
		infoList: [
			{
				title: '참여기간',
				detail: '2024년 09월 01일(일) ~ 2024년 09월 28일(토)',
			},
			{
				title: '결과발표',
				detail: '2024년 10월 중',
			},
			{
				title: '경품발송',
				detail: '2024년 11월 중',
			},
		],
		prdList: [
			{
				img: 'item_image_1.jpg',
				title: '갤럭시 Z플립 6',
				person: '(2명)',
			},
			{
				img: 'item_image_2.jpg',
				title: '동행제품 선물박스(20만원 상당)',
				person: '(6명)',
			},
			{
				img: 'item_image_3.jpg',
				title: '온누리상품권 5만원',
				person: '(30명)',
			},
			{
				img: 'item_image_4.jpg',
				title: '커피 기프티콘',
				person: '(80명)',
			},
			{
				img: 'item_image_5.jpg',
				title: '아이스크림 쿠폰',
				person: '(100명)',
			},
		],
		prectList: ['본 이벤트는 동행제품을 구매하고, 인스타그램과 페이스북에 필수 해시태그와 함께 게시물을 등록해 주신 후 이벤트 참여 버튼을 클릭하여 구글폼에 접수해 주시면 응모됩니다.'
			, '당첨자는 추첨 프로그램으로 선발됩니다.'
			, '당첨 후 경품의 제세 공과금(22%)는 본인이 부담해야 합니다.'
			, '이벤트 당첨 후, 당첨 안내 메시지에 회신이 없을 경우 당첨이 취소될 수 있습니다.'
			, '당첨자의 잘못된 정보 입력으로 인해 경품 누락이 될 경우 재 발송되지 않습니다.'
			, '이벤트 일정 및 경품 내역은 추후 주최 측의 사정에 따라 변동 가능합니다.'
		],
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('auth', ['session', 'isAuth']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		clickSnsEvent(e) {
			if (!this.chkLogin('로그인 후 이벤트 참여가 가능합니다.')) return false;
			//개인정보 동의 후 진행할 수 있도록 수정
			if (this.campn.prvcMktAgreYn === 'N') {
				this.visibleModal = true;
			} else {
				//this.$router.push('/ko/products/category');
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '이벤트 준비중입니다.' });
			}
		},
		chkLogin(title) {
			if (typeof this.session.userId == 'undefined') {
				const param = {
					title: title,
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			return true;
		},
		showAlert(msg) {
			this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: msg });
		},
		async prvcMktAgrePopup() {
			await this.$store.dispatch(`information/${ACT_SET_PRVC_MKT_AGRC}`, { campnId: this.campn.campnId }).then((res) => {
				if (isSuccess(res)) {
					this.campn.prvcMktAgreYn = 'Y';
					this.visibleModal = false;
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '이벤트 준비중입니다.' });
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.' });
				}
			});
		},
	},
};
</script>
